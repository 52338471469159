import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import MentionsContainer from '@/containers/Mentions/MentionsContainer';
import { PrismicMentionsPage } from '@/domain/PrismicMentionsPage';
import { PageContext } from '@/domain/PageContext';

interface MentionsTemplateProps {
    data: {
        prismicMentionsPage: PrismicMentionsPage;
    }
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicMentionsPage(id: { eq: $id }) {
      uid
      lang
      alternate_languages {
        uid
        lang
      }
      data {
        meta_title
        meta_description
        content {
          raw
        }
      }
    }
  }
`;

const MentionsTemplate = ({
    data,
    pageContext,
}: MentionsTemplateProps): JSX.Element => {
    const { alternate } = pageContext;
    const { lang, uid, alternate_languages } = data?.prismicMentionsPage;
    const { meta_title, meta_description } = data?.prismicMentionsPage?.data;

    const selectlang = {
        lang,
        uid: uid,
        alternate_languages,
    };
    const seo = {
        title: meta_title,
        description: meta_description,
        lang: lang,
        url: `${lang}`
    }
    const linkAlternate = alternate?.map(data => ({
        lang: data?.lang,
        url: `${data?.lang}/${data?.uid}`,
    }))

    return (
        <Layout selectLang={selectlang}>
            <SEO
                lang={seo.lang}
                title={`${seo.title}`}
                url={seo.url}
                description={seo.description}
                alternate={linkAlternate}
            />
            <MentionsContainer data={data} lang={lang} />
        </Layout>
    );
};

export default MentionsTemplate;
