import React from 'react';
import styled from '@emotion/styled';

import { Content } from '@/components/molecules';
import { PrismicMentionsPage } from '@/domain/PrismicMentionsPage';

interface MentionsContainerProps {
    data: {
        prismicMentionsPage: PrismicMentionsPage;
    };
    lang: string;
}

const MentionsContainerRoot = styled.section``;

const MentionsContainer = ({ data, lang }: MentionsContainerProps): JSX.Element => {
    const { content } = data?.prismicMentionsPage?.data;

    const ContentData = {
        content: content?.raw
    }

    return (
        <MentionsContainerRoot>
            <Content {...ContentData} />
        </MentionsContainerRoot>
    );
};

export default MentionsContainer;